import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminAmazonCategoryDto, AdminAmazonProductDto, AdminAmazonProductTypeDto, AdminFindAmazonCategoryDto, AdminFindAmazonProductDto, AdminFindAmazonProductTypeDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminAmazonService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(adminFindEbayProductDto: AdminFindAmazonProductDto) {
    return await this.httpService.get<PageResultDto<AdminAmazonProductDto>>(`/admin/v1/channel/amazon/products`, adminFindEbayProductDto)
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<AdminAmazonProductDto>(`/admin/v1/channel/amazon/products/${id}`)
  }

  async findCategory(adminFindAmazonCategoryDto: AdminFindAmazonCategoryDto) {
    return await this.httpService.get<PageResultDto<AdminAmazonCategoryDto>>(`/admin/v1/channel/amazon/categories`, adminFindAmazonCategoryDto)
  }

  async findProductType(adminFindAmazonCategoryDto: AdminFindAmazonProductTypeDto) {
    return await this.httpService.get<PageResultDto<AdminAmazonProductTypeDto>>(`/admin/v1/channel/amazon/product-types`, adminFindAmazonCategoryDto)
  }

  async findOneCategory(id: string) {
    return await this.httpService.get<AdminAmazonCategoryDto>(`/admin/v1/channel/amazon/categories/${id}`)
  }

  async findOneProductType(id: string) {
    return await this.httpService.get<AdminAmazonProductTypeDto>(`/admin/v1/channel/amazon/product-types/${id}`)
  }

  async updateCategory(amazonCategoryId: string, categoryId: string, amazonProductTypeId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/channel/amazon/categories/${amazonCategoryId}`, { categoryId, amazonProductTypeId })
  }

  async updateProductType(amazonCategoryId: string, categoryId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/channel/amazon/product-types/${amazonCategoryId}`, { categoryId })
  }
}
