import { CategoryDto, TenantDto } from "."
import { BaseDto } from "./base.dto"

import { PagingDto } from "./paging.dto"
import { ProductDto } from "./product.dto"

export class AdminAmazonProductDto extends BaseDto {
  channelSku: string
  product: ProductDto
  tenant: TenantDto
  request: any
  response: any
  errors: any[]
  gtin: string
  status: string
  amazonQuantity: string
  sku: string
}

export class AdminFindAmazonProductDto extends PagingDto {
  searchText?: string
}

export class AdminFindAmazonCategoryDto extends PagingDto {
  searchText?: string
  categoryId?: string
  ebayCategoryId?: string
}

export class AdminFindAmazonProductTypeDto extends PagingDto {
  searchText?: string
  categoryId?: string
  amazonProductTypeId?: string
}

export class AdminAmazonProductTypeDto extends BaseDto {
  name: string
  displayName: string
  definitions: any
  schema: any
  required: string[]
  requirements: string[]
  enabled: boolean
  requirementsResponse: any

  static create() {
    return new AdminAmazonProductTypeDto()
  }
}

export class AdminAmazonCategoryMappingDto {
  id: string
  categoryId: string
  amazonCategoryId: string
  amazonProductTypeId: string
}

export class AdminAmazonCategoryDto extends BaseDto {
  name: string
  category: CategoryDto
  enabled: boolean
  productTypeDefinitions: any
  browsePathByName: string
  hasChildren: boolean
  response: any
  browsePathById: string
  browseNodeId: string
  parentBrowseNodeId: string
  amazonCategoryMappings: AdminAmazonCategoryMappingDto[]

  static create() {
    return new AdminAmazonCategoryDto()
  }
}

export enum AmazonProductStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  TRANSMIT = 'TRANSMIT',
  DATA_FIX = 'DATA_FIX',
  UPDATE_UNPUBLISHED_OFFER_IN_PROGRESS = 'UPDATE_UNPUBLISHED_OFFER_IN_PROGRESS',
  UPDATE_PUBLISHED_OFFER_IN_PROGRESS = 'UPDATE_PUBLISHED_OFFER_IN_PROGRESS',
  CREATE_OFFER_IN_PROGRESS = 'CREATE_OFFER_IN_PROGRESS',
  PUBLISH_IN_PROGRESS = 'PUBLISH_IN_PROGRESS'
}