import { BaseDto } from "./base.dto"
import { PagingDto } from "./paging.dto"

import { ProductDto } from "./product.dto"

export class AmazonProductDto extends BaseDto {
  product: ProductDto
  sku: string
  channelSku: string
  status: string
  amazonQuantity: string
  request: any
  response: any
  errors: any[]

  static create() {
    return new AmazonProductDto()
  }
}

export class FindAmazonProductDto extends PagingDto {
  searchText?: string
  statuses?: string[]
}

// export class ShipEbayOrderDto {
//   orderNumber: string
//   trackingNumber: string
//   shippingCarrierCode: string
// }

// export class CancelEbayOrderDto {
//   comment: string
//   reason: string
//   orderNumber: string
// }