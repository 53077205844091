import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AmazonProductDto, FindAmazonProductDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AmazonService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(findProductDto: FindAmazonProductDto) {
    return await this.httpService.get<PageResultDto<AmazonProductDto>>(`/merchant/v1/channel/amazon/products`, findProductDto)
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<AmazonProductDto>(`/merchant/v1/channel/amazon/products/${id}`)
  }

  // async cancelOrder(cancelOrderDto: CancelEbayOrderDto) {
  //   return await this.httpService.post<any>(`/merchant/v1/channel/amazon/orders/cancel`, cancelOrderDto)
  // }

  // async shipOrder(shipChannelOrderDto: ShipEbayOrderDto) {
  //   return await this.httpService.post<any>(`/merchant/v1/channel/amazon/orders/ship`, shipChannelOrderDto)
  // }

  async updateReSync(id: string) {
    return await this.httpService.post<any>(`/merchant/v1/channel/amazon/products/re-sync`, { productId: id })
  }
}
